// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-meet-the-team-js": () => import("./../../../src/pages/about-us/meet-the-team.js" /* webpackChunkName: "component---src-pages-about-us-meet-the-team-js" */),
  "component---src-pages-about-us-testimonials-js": () => import("./../../../src/pages/about-us/testimonials.js" /* webpackChunkName: "component---src-pages-about-us-testimonials-js" */),
  "component---src-pages-find-a-builder-contact-us-js": () => import("./../../../src/pages/find-a-builder/contact-us.js" /* webpackChunkName: "component---src-pages-find-a-builder-contact-us-js" */),
  "component---src-pages-find-a-builder-index-js": () => import("./../../../src/pages/find-a-builder/index.js" /* webpackChunkName: "component---src-pages-find-a-builder-index-js" */),
  "component---src-pages-find-a-builder-new-build-js": () => import("./../../../src/pages/find-a-builder/new-build.js" /* webpackChunkName: "component---src-pages-find-a-builder-new-build-js" */),
  "component---src-pages-find-a-builder-renovation-js": () => import("./../../../src/pages/find-a-builder/renovation.js" /* webpackChunkName: "component---src-pages-find-a-builder-renovation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-blog-index-js": () => import("./../../../src/pages/media/blog/index.js" /* webpackChunkName: "component---src-pages-media-blog-index-js" */),
  "component---src-pages-media-blog-strapi-blogs-slug-js": () => import("./../../../src/pages/media/blog/{StrapiBlogs.slug}.js" /* webpackChunkName: "component---src-pages-media-blog-strapi-blogs-slug-js" */),
  "component---src-pages-media-press-js": () => import("./../../../src/pages/media/press.js" /* webpackChunkName: "component---src-pages-media-press-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-transparency-js": () => import("./../../../src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */),
  "component---src-pages-what-we-do-compare-the-builders-js": () => import("./../../../src/pages/what-we-do/compare-the-builders.js" /* webpackChunkName: "component---src-pages-what-we-do-compare-the-builders-js" */),
  "component---src-pages-what-we-do-faq-js": () => import("./../../../src/pages/what-we-do/faq.js" /* webpackChunkName: "component---src-pages-what-we-do-faq-js" */),
  "component---src-pages-what-we-do-index-js": () => import("./../../../src/pages/what-we-do/index.js" /* webpackChunkName: "component---src-pages-what-we-do-index-js" */),
  "component---src-pages-what-we-do-why-use-builder-finders-js": () => import("./../../../src/pages/what-we-do/why-use-builder-finders.js" /* webpackChunkName: "component---src-pages-what-we-do-why-use-builder-finders-js" */)
}

